import React from 'react';
import style from '../styles/style.css';

const StubMobile = () => {
    return (
        <div className='div-stub-root'>
            <div className='div-stub-child'>
                <p className='p-stub'>
                    Мобильная версия отсутствует. Пока...
                </p>
            </div>
        </div>
    )
}
export default StubMobile;